let appMode = process.env.REACT_APP_ENV;
let ASSET_URL = "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/tawasi-s3/";
let URL;

console.log("appMode", appMode);

// 3.20.147.34

if (appMode === "development") {


  URL = "https://dev.backend.tawasi.online/api/"
  // URL = "http://localhost:5500/api/";
} else {
  URL = "https://backend.tawasi.online/api/";
  // URL = "http://localhost:5500/api/";
  //looo
}

let apiPath = {
  baseURL: URL,
  assetURL: ASSET_URL,
  dashboard: "admin/dashboard",

  listOrder: "admin/order/list",
  viewOrder: "admin/order/view",
  statusOrder: "admin/status",

  listTransaction: "admin/transaction/list",
  viewTransaction: "admin/transaction/view",

  // Auth API
  logout: "admin/auth/logout",
  login: "admin/auth/login",

  profile: "admin/auth/get-profile",
  updateProfile: "admin/auth/update-profile",
  changePassword: "admin/auth/change-password",
  updateAppSetting: "admin/auth/update-app-setting",

  forgotPassword: "admin/auth/forgot-password",
  verifyOTP: "admin/auth/verify-otp",
  resetPassword: "admin/auth/reset-password",

  // Customer APIs
  listCustomer: "admin/customer",
  addEditCustomer: "admin/customer",
  deleteCustomer: "admin/delete",
  statusCustomer: "admin/customer/status",
  viewCustomer: "admin/customer/view",
  importCustomer: "admin/customer/import-file",

  // SubAdmin APIs
  subAdmin: "admin/sub-admin",
  addEditSubAdmin: "admin/sub-admin",
  statusSubAdmin: "admin/sub-admin/status",
  viewSubAdmin: "admin/sub-admin/view",
  getModule: "admin/sub-admin/module-list",
  addPermission: "admin/sub-admin/add-permission",

  //collector
  collector: "admin/collector",
  location: "admin/service-location",

  // driver APIs
  driver: "admin/driver",
  importDealer: "admin/dealer/import-file",

  checkCode: "admin/discount/check-code",
  discount: "admin/discount",
  revenue: "admin/revenue",
  report: "admin/report",
  request: "admin/request",

  banner: "admin/banner",
  history: "admin/delivery-history",
  adminCommon: "admin/common",

  // Content APIs
  content: "admin/content",
  notification: "admin/notification",
  rating: "admin/rating",

  finance: "admin/finance",

  // EmailTemplate APIs
  listEmailTemplate: "admin/email-template/list",
  addEditEmailTemplate: "admin/email-template/add-edit",
  statusEmailTemplate: "admin/email-template/status",
  viewEmailTemplate: "admin/email-template/view",

  // Blog APIs
  listBlog: "admin/blog/list",
  addEditBlog: "admin/blog/add-edit",
  statusBlog: "admin/blog/status",
  viewBlog: "admin/blog/view",

  //category
  listCategory: "admin/category",
  statusCategory: "admin/category/status",

  //restaurant
  restaurant: "admin/restaurant",

  //company
  company: "admin/company",

  getAppSetting: "common/app-setting",

  // Size APIs
  size: "admin/size",

  //order
  order: "admin/order",

  //log
  log: "admin/activity-log",

  // delivery charge
  deliveryCharge: "admin/delivery-charge",

  //Vendor apis

  common: {
    restaurantCategories: "categories",
    foodCategories: "food-categories",
    countries: "",

    foodItems: "common/food-items",
    countries: "common/",
    cities: "",
  },

  // Auth API
  logout: "admin/auth/logout",
  signUp: "vendor/auth/sign-up",
};

export default apiPath;
