import { Image, Menu, Modal, Skeleton } from "antd";
import { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import Logo from "../../assets/images/Logo.png";
import Bio from "../../assets/images/side_nav/bi_collection.svg";
import Blog from "../../assets/images/side_nav/blog.svg";
import Cath from "../../assets/images/side_nav/cath.svg";
import Chat from "../../assets/images/side_nav/chat.svg";
import Cms from "../../assets/images/side_nav/cms.svg";
import Coust from "../../assets/images/side_nav/customer.svg";
import Dash from "../../assets/images/side_nav/dash.svg";
import Disk from "../../assets/images/side_nav/discount.svg";
import File from "../../assets/images/side_nav/file.svg";
import Log from "../../assets/images/side_nav/log.svg";
import Bell from "../../assets/images/side_nav/notification.svg";
import Order from "../../assets/images/side_nav/order.svg";
import Rest from "../../assets/images/side_nav/resturant.svg";
import Star from "../../assets/images/side_nav/star.svg";
import Sub from "../../assets/images/side_nav/sub.svg";
import User from "../../assets/images/side_nav/user.svg";
import { AuthContext } from "../../context/AuthContext";
import lang from "../../helper/langHelper";
import DeleteModal from "../DeleteModal";
import { useAppContext } from "../../context/AppContext";
import moment, { isMoment } from "moment";

export const countryWithAreas = [
  "646b2e0f46865f1f65565346", //Palestine
];

// export const menuItems = [
//   {
//     key: "dashboard-management",
//     path: "/dashboard",
//     icon: Dash,
//     label: lang("Dashboard"),
//     isShow: true,
//   },
//   {
//     key: "category-management",
//     label: `${lang("Category Management")}`,
//     icon: Cath,
//     path: "/category",
//   },
//   {
//     key: "restaurant-manager",
//     label: `${lang("Restaurant Management")}`,
//     icon: Rest,
//     path: "/restaurant",
//   },
//   {
//     key: "customer-manager",
//     label: `${lang("Customer Management")}`,
//     icon: Coust, //
//     path: "/customer",
//   },
//   {
//     key: "order-manager",
//     label: `${lang("Order Management")}`,
//     icon: Order,
//     path: "/order",
//   },
//   {
//     key: "driver-manager",
//     label: `${lang("Driver Management")}`,
//     icon: User,
//     path: "/drivers",
//   }, //
//   {
//     key: "sub-admin-manager",
//     path: "/sub-admin",
//     icon: Sub,
//     label: `${lang("Sub Admin Management")}`,
//   },
//   {
//     key: "cms-manager",
//     path: "/cms",
//     icon: Cms,
//     label: `${lang("CMS Management")}`,
//   },
//   {
//     key: "delivery-manager",
//     path: "/delivery",
//     icon: Blog,
//     label: `${lang("Delivery History")}`,
//   },
//   {
//     key: "discount-manager",
//     path: "/discount",
//     icon: Disk,
//     label: `${lang("Discount Management")}`,
//   },
//   {
//     key: "advertise-manager",
//     path: "/advertise",
//     icon: Star,
//     label: `${lang("Advertise Management")}`,
//   },
//   {
//     key: "rating-manager",
//     path: "/ratings",
//     icon: Star,
//     label: `${lang("Rating and Reviews")}`,
//   },
//   {
//     key: "report-manager",
//     path: "/reports",
//     icon: Chat,
//     label: `${lang("Reports")}`,
//   },
//   {
//     key: "finance-manager",
//     path: "/finance",
//     icon: File,
//     label: `${lang("Financial Management")}`,
//   },
//   {
//     key: "collector-manager",
//     path: "/collector",
//     icon: Bio,
//     label: `${lang("Collection Management")}`,
//   },
//   {
//     key: "service-location-manager",
//     path: "/locations",
//     icon: Disk,
//     label: `${lang("Service Location Management")}`,
//   },
//   // {
//   //   key: "customer-loyalty-points",
//   //   path: "/loyalty-points",
//   //   icon: Disk,
//   //   label: `${lang("Loyalty Points Management")}`,
//   // },
//   // {
//   //   key: "FAQ",
//   //   path: "/faqs",
//   //   icon: Disk,
//   //   label: `${lang("FAQ")}`,
//   // },
//   // {
//   //   key: "email-template-manager",
//   //   path: "/email-templates",
//   //   icon: Disk,
//   //   label: `${lang("Email Template Management")}`,
//   // }, 
//   {
//     key: "companies-manager",
//     path: "/companies",
//     icon: Disk,
//     label: `${lang("Company Management")}`,
//   },
//   {
//     key: "delivery-charge-manager",
//     path: "/delivery-charge",
//     icon: Disk,
//     label: `${lang("Delivery Charge Management")}`,
//   },
//   {
//     key: "Notifications",
//     path: "/notification",
//     icon: Bell,
//     label: `${lang("Notifications")}`,
//   },
// ];

export const menuItems =[
  {
    key: "dashboard-management",
    path: "/dashboard",
    icon: Dash,
    label: lang("Dashboard"),
    isShow: true,
    srNo: 1
  },
  {
    key: "category-management",
    label: lang("Category Management"),
    icon: Cath,
    path: "/category",
    srNo: 2
  },
  {
    key: "restaurant-manager",
    label: lang("Restaurant Management"),
    icon: Rest,
    path: "/restaurant",
    srNo: 3
  },
  {
    key: "customer-manager",
    label: lang("Customer Management"),
    icon: Coust,
    path: "/customer",
    srNo: 4
  },
  {
    key: "order-manager",
    label: lang("Order Management"),
    icon: Order,
    path: "/order",
    srNo: 5
  },
  {
    key: "driver-manager",
    label: lang("Driver Management"),
    icon: User,
    path: "/drivers",
    srNo: 6
  },
  {
    key: "sub-admin-manager",
    path: "/sub-admin",
    icon: Sub,
    label: lang("Sub Admin Management"),
    srNo: 7
  },
  {
    key: "cms-manager",
    path: "/cms",
    icon: Cms,
    label: lang("CMS Management"),
    srNo: 8
  },
  {
    key: "delivery-manager",
    path: "/delivery",
    icon: Blog,
    label: lang("Delivery History"),
    srNo: 9
  },
  {
    key: "discount-manager",
    path: "/discount",
    icon: Disk,
    label: lang("Discount Management"),
    srNo: 10
  },
  {
    key: "advertise-manager",
    path: "/advertise",
    icon: Star,
    label: lang("Advertise Management"),
    srNo: 11
  },
  {
    key: "rating-manager",
    path: "/ratings",
    icon: Star,
    label: lang("Rating and Reviews"),
    srNo: 12
  },
  {
    key: "report-manager",
    path: "/reports",
    icon: Chat,
    label: lang("Reports"),
    srNo: 13
  },
  {
    key: "finance-manager",
    path: "/finance",
    icon: File,
    label: lang("Financial Management"),
    srNo: 14
  },
  {
    key: "collector-manager",
    path: "/collector",
    icon: Bio,
    label: lang("Collection Management"),
    srNo: 15
  },
  {
    key: "service-location-manager",
    path: "/locations",
    icon: Disk,
    label: lang("Service Location Management"),
    srNo: 16
  },
  {
    key: "companies-manager",
    path: "/companies",
    icon: Disk,
    label: lang("Company Management"),
    srNo: 17
  },
  // {
  //   key: "delivery-charge-manager",
  //   path: "/delivery-charge",
  //   icon: Disk,
  //   label: lang("Delivery Charge Management"),
  //   srNo: 18
  // },
  {
    key: "Notifications",
    path: "/notification",
    icon: Bell,
    label: lang("Notifications"),
    srNo: 19
  }
]


function Sidenav({ color }) {
  const { pathname } = useLocation();
  const { userProfile, logout, setUserProfile } = useContext(AuthContext);
  const { country } = useAppContext();

  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [menuLinks, setMenuLinks] = useState([]);
  const [menuMode, setMenuMode] = useState("vertical");
  const [currentDateTime, setCurrentDateTime] = useState(moment());

  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);

  const showDeleteConfirm = (record) => {
    logout();
  };

  const renderTitle = (item) => {
    return (
      <>
        <Image preview={false} src={item.icon} />
        <span className="label">{item.label}</span>
      </>
    );
  };

  useEffect(() => {
    setLoading(true);
    if (!userProfile) return;

    const newItem = {
      key: "delivery-charge-manager",
      path: "/delivery-charge",
      icon: Disk,
      label: lang("Delivery Charge Management"),
      srNo: 18
    }

    if (userProfile.type === "Admin") {
      const items =  country &&  country?.country_id &&  countryWithAreas.includes(country?.country_id)  ?[
      ...menuItems.slice(0, 17),
      newItem,
      ...menuItems.slice(17)
    ]  : menuItems;

      setMenuLinks(items);
      setLoading(false);
      return;
    }

    const items =  country &&    country?.country_id &&  countryWithAreas.includes(country?.country_id) ?[
      ...menuItems.slice(0, 17),
      newItem,
      ...menuItems.slice(17)
    ]  : menuItems;

    const newArray = items.filter((item) => {
      if (item.isShow) {
        return true;
      } else {
        return userProfile.permission.includes(item.key);
      }
    });

    const links = newArray.filter((item) => {
      if (item?.children?.length) {
        return true;
      } else if (!item?.children) {
        return true;
      } else {
        return false;
      }
    });

    setMenuLinks(links);
    setLoading(false);
  }, [userProfile, country]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setMenuMode("inline");
      } else {
        setMenuMode("vertical");
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const updateDateTime = () => {
      setCurrentDateTime(moment());
    };

    const intervalId = setInterval(updateDateTime, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      {loading ? (
        [1, 2, 3, 4, 5, 6].map((item) => <Skeleton active key={item} />)
      ) : (
        <>
          <div className="brand-logo">
            <NavLink to="" className="imgOuter">
              <img className="" src={Logo} alt="" />
            </NavLink>
            <p style={{ color: "white" }}>
              {currentDateTime.format("YYYY-MM-DD hh:mm a")}
            </p>
          </div>
          <Menu inlineCollapsed={false} mode={menuMode} className="sideNavMain">
            {menuLinks.map((item) => {
              if (item.children) {
                return (
                  <>
                    <Menu.SubMenu
                      key={item.key}
                      title={
                        <>
                          <span className="icon">
                            <Image preview={false} src={item.icon} />
                          </span>
                          <span className="label">{item.label}</span>
                        </>
                      }
                    >
                      {item.children.map((child) => (
                        <Menu.Item key={child.key}>
                          <NavLink to={child.path}>{child.label}</NavLink>
                        </Menu.Item>
                      ))}
                    </Menu.SubMenu>
                  </>
                );
              }

              return (
                <Menu.Item key={item.key}>
                  <NavLink to={item.path}>{renderTitle(item)}</NavLink>
                </Menu.Item>
              );
            })}

            <Menu.Item onClick={showDeleteConfirm}>
              <NavLink to={"#"}>
                <>
                  <Image preview={false} src={Log} />
                  <span className="label">{lang("Logout")}</span>
                </>
              </NavLink>
            </Menu.Item>
          </Menu>
        </>
      )}
      {isLogoutModalVisible && (
        <DeleteModal
          title={"Logout"}
          subtitle={`Are you sure you want to Logout the Application?`}
          show={isLogoutModalVisible}
          hide={() => {
            setIsLogoutModalVisible(false);
          }}
          onOk={() => logout()}
        />
      )}
    </>
  );
}

export default Sidenav;
